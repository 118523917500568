<div class="servico-card">
  <div class="image">
    <div class="row space-evenly">
      <div class="col servico-cover">
        <img class="card-img-top"
          src="{{ servico.ImagemPrincipal != null && servico.ImagemPrincipal != '' ? servico.ImagemPrincipal : backgroundimage}}" />
      </div>
    </div>
  </div>

  <div class="card-body">
    <div>
          <h4 class="fonte-cabecalho-card"> {{servico.Titulo}}
          </h4>
          <div [ngSwitch]="servico.CategoriaServico" *ngIf="servico.Tipo != 'Ticket'">
            <h4><span *ngSwitchCase="0">({{'REGULAR' | translate}})</span>
              <span *ngSwitchCase="1">({{'PRIVATIVO' | translate}})</span>
              <span *ngSwitchCase="2">({{'LUXO' | translate}})</span></h4>
          </div>
          <div>
            <strong>{{'Data do serviço' | translate}}:</strong> {{servico.De | date : 'dd/MM/yyyy'}}
          </div>
          <div id="modalLink" data-bs-toggle="modal" data-bs-target="#detailsModal">
            <button class="btn btn-success">{{"Detalhes do Serviço" | translate }}</button>
          </div>
      <!-- <div class="col" [ngSwitch]="servico.CategoriaServico">
        <h4 class="card-subtitle">{{ servico.Titulo }}</h4>
        <h4 class="fonte-cabecalho-card"> {{servico.Titulo}}
          <span *ngSwitchCase="0">({{'REGULAR' | translate}})</span>
          <span *ngSwitchCase="1">({{'PRIVATIVO' | translate}})</span>
          <span *ngSwitchCase="2">({{'LUXO' | translate}})</span>
        </h4>
        <div id="modalLink" data-bs-toggle="modal" data-bs-target="#detailsModal" class="card-footer">
          <a>{{"Detalhes do Serviço" | translate }}</a>
        </div>
      </div> -->
    </div>
  </div>

  <div class="preco" *ngIf="!pacote">
    <div class="row text-center">
      <div class="col">
        {{"A partir de" | translate}}:
      </div>
    </div>

    <div class="row text-center">
      <div class="col">
        <h4>{{servico.MoedaListagem.Simbolo}} {{ servico.ValorListagem | currency:'BRL':'':'2.2-2' }}</h4>
      </div>
    </div>
  </div>
</div>

<!-- Service Details Modal -->
<div class="modal fade" tabindex="-1" id="detailsModal" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{"Detalhes do Serviço" | translate }}</h5>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="tab-content">
            <div id="home" class="tab-pane active">
              <h5>{{"Localização" | translate}}</h5>
              <p>
                {{ servico.Cidade.Nome }}
              </p>
              <h5>{{"Descrição Geral" | translate}}</h5>
              <p [innerHTML]="servico.Descricao"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a type="button" class="btn btn-success" data-bs-dismiss="modal">{{"Voltar" | translate}}</a>
      </div>
    </div>
  </div>
</div>

