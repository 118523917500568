import { Cidade } from '../endereco.model';

// tslint:disable: no-use-before-declare

export class Servico {
  public Id: number;
  public descricao: string;
  public selecionado: boolean;
  public CodigoFornecedor: any;
  public DiasSemana: any;
  public ExtraIntegracao2: ExtraIntegracao;
  public ListDiasSemana: any;
  public Tarifas: any;
  public DataSelecionada: any;
  public DatasDisponiveis: any;
}

export enum TIPO {
  ATIVIDADE,
  INGRESSO,
  TRANSFER,
}

export class BuscaServicoRQ {
  public De: any;
  public Ate: any;
  public Adt: number;
  public IdadesChd: number[] = [];
  public Cidade: Cidade = new Cidade();
  public Tipo: TIPO;
  public Guid: string;
  public AgenciaId: any;
  public FiltroServico: FiltroServico = new FiltroServico();

}

class ExtraIntegracao {
  public NomeFornecedor: string;
  public EnderecoFornecedor: string;
  public TelefoneFornecedor: string;
  public CidadeFornecedor: string;
  public EstadoFornecedor: string;
  public PaisFornecedor: string;
  public Token: string;
  public CategoriaServico: string;
}

export class FiltroServico {
  public ValorMinimo: number;
  public ValorMaximo: number;
  public Titulo: string;
  public Tipo: string = "";
  public Categoria : number = -1;
}

export enum tipoServico {
  ATIVIDADE = 0,
  TICKET = 1,
  TRANSFER = 2,

}
