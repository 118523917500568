import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-servico-detalhes',
    templateUrl: './servico-detalhes.component.html',
    styleUrls: ['./servico-detalhes.component.scss']
})
export class ServicoDetalhesComponent implements OnInit {

    @Input() objServico: any;
    fornecedor = "";
    diasDaSemana = [
        { diaSemana: 'Dom' },
        { diaSemana: 'Seg' },
        { diaSemana: 'Ter' },
        { diaSemana: 'Qua' },
        { diaSemana: 'Qui' },
        { diaSemana: 'Sex' },
        { diaSemana: 'Sáb' }
    ]

    constructor(private modalService: NgbModal) { }

    ngOnInit(): void {
        if (this.isValidJson(this.objServico.ExtraIntegracao)) {
            let obj = JSON.parse(this.objServico.ExtraIntegracao);
            let extras ="";
            if(obj.Extras){
                extras = obj.Extras.replace(/\'/g, "\"");
            } 
            if (this.isValidJson(extras)) {
                obj = JSON.parse(extras);
            }
            if(obj.NomeFornecedor){
                this.fornecedor = "Fornecedor: " + obj.NomeFornecedor;
            }
            
        }
    }

    isValidJson(jsonString) {
        try {
            JSON.parse(jsonString);
            return true; // Se a string foi parseada sem erros, é um JSON válido
        } catch (e) {
            return false; // Se um erro foi lançado, a string não é um JSON válido
        }
    }

    fecharModal() {
        this.modalService.dismissAll();
    }

}
