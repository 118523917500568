// tslint:disable: member-ordering
// tslint:disable: ban-types
// tslint:disable: max-line-length
// tslint:disable: prefer-for-of
// tslint:disable: only-arrow-functions
// tslint:disable: no-shadowed-variable
import { BACKGROUNDIMAGE } from 'src/app/app.config';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Options, LabelType } from 'ng5-slider';
import { Observable, Subject, Subscription } from 'rxjs';
import { Busca, FiltroHotel, Hotel, Paginacao } from 'src/app/model';
import { CidadeService, HotelService } from 'src/app/services';
export const BUSCA = 'busca';
import * as cloneDeep from 'lodash/cloneDeep';
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lista-hotel',
  templateUrl: './lista-hotel.component.html',
  styleUrls: ['./lista-hotel.component.scss']
})
export class ListaHotelComponent implements OnInit {

  constructor(
    private hotelService: HotelService,
    private cidadeService: CidadeService,
    public _router: Router,
    private modalService: NgbModal,
    public route: ActivatedRoute,
  ) { }

  modelChanged: Subject<any> = new Subject<any>();
  @Output() selecionou = new EventEmitter<any>();
  @Output() avancar = new EventEmitter<any>();
  @Output() removeu = new EventEmitter<any>();
  @Input() busca: Busca;
  @Input() events: Observable<void>;
  @Input() Orcamento: Hotel[] = [];

  private eventsSubscription: Subscription;

  FiltroHotel: FiltroHotel = new FiltroHotel();
  paginacao: Paginacao = new Paginacao();
  Fornecedores: String[] = [];
  hoteis: Hotel[] = [];
  nome_Hotel_Search: any;
  itemBusca: any = null;
  progress: any;
  buscasAtivas = [];
  integradores = [];
  carregando = true;
  buscando = false;
  rebusca = false;
  cidades = [];
  indiceAtual = 0;
  contador = 0;
  rating = 5;
  mensagemErro: '';
  guid = '';
  backgroundimage = BACKGROUNDIMAGE;

  hotelDetails: any;

  ValorAproximado_Search: number;
  valorMinimo_Search: number;
  valorMaximo_Search: number;
  Fornecedor_Search: any;
  Pensoes_Search: any;

  setEstrelas_Search: number;

  OpcoesValor: Options = {
    floor: 0,
    ceil: 99999,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Min:</b> $' + value;
        case LabelType.High:
          return '<b>Max:</b> $' + value;
        default:
          return '$' + value;
      }
    }
  };

  OpcoesClassificacao: Options = {
    floor: 0,
    ceil: 5,
    getSelectionBarColor: (value: number): string => {
      return '#00DB00';
    },
    getPointerColor: (value: number): string => {
      return '#00DB00';
    },
    animate: false
  };

  timer = null;
  buscarDebounce() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.refazerBusca(this.busca), 600);

  }

  ngOnInit() {

    this.busca.trechos = this.busca.trechos.map(x => {
      x.dataIda = moment(x.dataIda);
      x.minDataIda = moment(x.minDataIda);

      if (this.busca.tipoBuscaAereo === 'ida-volta') {
        x.dataVolta = moment(x.dataVolta);
        x.minDataVolta = moment(x.minDataVolta);
      } else {
        x.dataVolta = moment(x.dataIda.format('YYYY-MM-DD')).add(1, 'day');
        x.minDataVolta = moment(x.dataIda.format('YYYY-MM-DD')).add(1, 'day');
      }
      return x;

    });

    if (!this.busca.trechos[0].cidadeDestino.display_field && this.busca.trechos[0].AeroportoChegada.display_field) {
      this.busca.trechos[0].cidadeDestino.display_field = this.busca.trechos[0].cidadeDestino.Nome + ' - ' + this.busca.trechos[0].cidadeDestino.Estado.Nome + ' / ' + this.busca.trechos[0].cidadeDestino.Estado.Pais.Nome;
    }

    this.buscar();
    this.eventsSubscription = this.events.subscribe((busca) => this.refazerBusca(busca));

  }

  rotaAvancar() {
    this.avancar.emit('');
  }

  buscar() {
    const buscaHotel = this.busca.getBuscaHotel();

    // buscaHotel.FiltroHotel.ValorMaximo = this.ValorAproximado_Search;

    buscaHotel.FiltroHotel = this.FiltroHotel;

    buscaHotel.Rebusca = this.rebusca;

    if (!this.buscando) {
      this.hoteis = [];
    }

    this.buscando = true;
    this.hotelService
      .buscarHotel(buscaHotel, this.paginacao.NumeroPagina)
      .subscribe(
        dados => {
          this.carregando = false;
          this.buscasAtivas = JSON.parse(dados.MetaData.BuscasAtivas);
          this.integradores = JSON.parse(dados.MetaData.BuscasAtivas);
          this.busca.guid = dados.MetaData.Guid;

          this.paginacao.NumeroPagina = dados.MetaData.NumeroPagina;
          this.paginacao.TotalItens = dados.MetaData.TotalItens;
          this.paginacao.ItensPorPagina = dados.MetaData.itensPorPagina;
          this.paginacao.TotalPaginas = dados.MetaData.TotalPaginas;

          const meta = JSON.parse(dados.MetaData.MetaDados);
          if (meta != null) {
            const meta = JSON.parse(dados.MetaData.MetaDados);

            this.Fornecedores = meta.Fornecedores;


            if (!(this.FiltroHotel.ValorMinimo > 0)) {

              this.FiltroHotel.ValorMinimo = meta.ValorMinimo;
              this.FiltroHotel.ValorMaximo = meta.ValorMaximo;
              // this.valorMaximo_Search = meta.ValorMaximo;
              // this.valorMinimo_Search = meta.ValorMinimo;

            }

            this.FiltroHotel.Pensoes = meta.Pensoes;

            const newOptions: Options = Object.assign({}, this.OpcoesValor);
            newOptions.floor = meta.ValorMinimo;
            newOptions.ceil = meta.ValorMaximo;
            this.OpcoesValor = newOptions;

          } else {
            this.FiltroHotel.ValorMinimo = 0;
            this.FiltroHotel.ValorMaximo = 0;
            this.Fornecedores = [];

            const newOptions: Options = Object.assign({}, this.OpcoesValor);
            newOptions.floor = 0;
            newOptions.ceil = 20000;
            this.OpcoesValor = newOptions;
          }

          window.sessionStorage.setItem('busca', JSON.stringify(this.busca));

          this.hoteis = dados.Items.map(x => {
            x.Id = x.Id * -1;
            x.selecionado = this.hotelSelecionado(x);
            x.QuantidadeSelecionada = this.hotelSelecionado(x);

            const opcoes = x.Quartos.map(q => q.Pesquisa)
              .filter(this.distinct)
              .sort();
            x.opcoes = [];
            for (let i = 0; i < opcoes.length; i++) {
              x.opcoes.push(x.Quartos.filter(x => x.Pesquisa == opcoes[i]));
            }
            x.Quartos = [];
            x.melhorPreco = 0;
            for (let i = 0; i < opcoes.length; i++) {
              x.Quartos.push(x.opcoes[i][0]);
              x.melhorPreco += x.opcoes[i][0].ValorListagem;
            }
            return x;

          });

          this.contador++;

          if (this.buscasAtivas.length > 0 && (window.location.href.split('/').indexOf('hotel') !== -1 || window.location.href.split('/').indexOf('adicionar') !== -1)) {
            setTimeout(() => {
              this.buscar();
            }, 3000);
          } else {
            this.buscando = false;
          }
        },
        erro => {
          console.log(erro);
          this.buscando = false;
          this.carregando = false;
          this.mensagemErro = erro.error.mensagem;
        }
      );
  }


  buscarPagina(pagina) {
    this.paginacao.NumeroPagina = pagina;
    this.buscar();
  }

  refazerBusca(busca) {
    if (this.aba() == 'hotel') {
      this.busca = busca;
      this.busca.primeira = false;
      window.scroll(0, 0);
      this.paginacao.NumeroPagina = 1;
      this.buscando = true;
      this.buscar();
    }
  }

  abrirModal(content, hotel){
    this.hotelDetails = hotel;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' })
  }

  detalhesHotel(hotel) {
    this.hotelService.detalhesHotel(hotel).subscribe(
      dados => {
      }, erro => {
        console.log(erro);
      });
  }

  FornecedorSelecionado(fornecedor) {
    return this.FiltroHotel.Fornecedores.indexOf(fornecedor) >= 0;
  }

  selecionarFornecedor(fornecedor) {

    setTimeout(() => {
      if (this.FornecedorSelecionado(fornecedor)) {
        this.FiltroHotel.Fornecedores = this.FiltroHotel.Fornecedores.filter(x => x !== fornecedor);
      } else {
        this.FiltroHotel.Fornecedores.push(fornecedor);
      }

      //this.refazerBusca(this.busca);
    }, 600);
  }

  selecionarHotel(hotel) {
    hotel.selecionado = true;

    if (hotel.QuantidadeSelecionada > 0) {
      let hotelClone = cloneDeep(hotel);

      this.Orcamento.push(hotelClone);

      hotelClone.QuantidadeSelecionada = this.Orcamento.filter(x => x.CodigoFornecedor == hotelClone.CodigoFornecedor && x.Fornecedor == hotelClone.Fornecedor).length;

      hotel.QuantidadeSelecionada = hotelClone.QuantidadeSelecionada;

      this.selecionou.emit(hotelClone);
    }
    else {

      let hotelClone = cloneDeep(hotel);

      this.Orcamento.push(hotelClone);

      hotel.QuantidadeSelecionada = this.Orcamento.filter(x => x.CodigoFornecedor == hotelClone.CodigoFornecedor && x.Fornecedor == hotelClone.Fornecedor).length;

      this.selecionou.emit(hotelClone);
    }
  }

  hotelSelecionado(hotel) {
    const dado = this.Orcamento.filter(
      x => x.CodigoFornecedor == hotel.CodigoFornecedor
      && x.Fornecedor == hotel.Fornecedor
    );
    return dado.length > 0;
  }

  removerHotel(hotel) {
    let indice = -1;
    for (let i = 0; i < this.Orcamento.length; i++) {
      if (this.Orcamento[i].Id == hotel.Id) {
        indice = i;
      }
    }
    if (indice >= 0) {
      this.Orcamento.splice(indice, 1);

      hotel.QuantidadeSelecionada = this.Orcamento.filter(x => x.CodigoFornecedor == hotel.CodigoFornecedor && x.Fornecedor == hotel.Fornecedor).length;

      if (hotel.QuantidadeSelecionada == 0) {
        hotel.selecionado = false;
      }

      this.removeu.emit(hotel);
    }
  }

  somaTotal(arr) {
    return arr
      .map(x => x.ValorListagem)
      .reduce(function(a, b) {
        return a + b;
      }, 0);
  }

  distinct = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  selecionaCidadeDestino(item, trecho) {
    trecho.cidadeDestino = item;
    this.busca.primeira = false;
    this.rebusca = true;
  }

  buscaCidade(val: string) {
    this.cidadeService.buscarOrcamento(val, 1).subscribe(
      dados => {
        this.cidades = dados.Items.map(x => {
          x.display_field =
            x.Nome + ' - ' + x.Estado.Nome + ' / ' + x.Estado.Pais.Nome;
          return x;
        });
      },
      erro => {
        console.log(erro);
        this.cidades = [];
      }
    );
  }

  setDataIda(event, trechos) {
    trechos[0].minDataVolta = moment(trechos[0].dataIda).add(1, 'days');
    trechos[0].dataVolta = trechos[0].minDataVolta;
  }

  onChange(event) {
    this.busca.primeira = false;
    this.rebusca = true;
  }

  limparFiltros() {
    this.FiltroHotel = new FiltroHotel();
    this.removeCheck();
    this.buscar();
  }

  expandeHotel(hotel) {
    hotel.BloqueiaAccordion = !hotel.BloqueiaAccordion;
    hotel.ExpandirAccordion = !hotel.ExpandirAccordion;
  }

  removeCheck() {
    const els = document.querySelectorAll('label.btn.active');

    for (let i = 0; i < els.length; i++) {
      els[i].classList.remove('active');
    }

  }

  selecionarPensao(pensao) {

    setTimeout(() => {

      if (this.pensaoSelecionada(pensao)) {
        this.FiltroHotel.Pensao = this.FiltroHotel.Pensao.filter(x => x !== pensao);
      } else {
        this.FiltroHotel.Pensao.push(pensao);
      }

      //this.refazerBusca(this.busca);
    }, 600);
  }

  pensaoSelecionada(pensao) {
    return this.FiltroHotel.Pensao.indexOf(pensao) >= 0;
  }

  // setVMax(value){
  //   this.ValorAproximado_Search = value;
  // }
  //
  // setEstrelas(value){
  //   this.FiltroHotel = this.FiltroHotel;
  //   this.FiltroHotel.EstrelasMinimo = 1;
  //   this.FiltroHotel.EstrelasMaximo = value;
  // }

  aba() {
    if (this.route.snapshot.params.tipo != null) {
      return this.route.snapshot.params.tipo;
    } else {
      let x = this._router.url.split("/");

      return x[x.length - 1];
    }
    return "";
  }

}
