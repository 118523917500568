import { Component, EventEmitter, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { API, BACKGROUNDIMAGE } from 'src/app/app.config';

import { Busca, BuscaServicoRQ } from 'src/app/model';

@Component({
    selector: 'app-servico',
    templateUrl: './servico.component.html',
    styleUrls: ['./servico.component.scss']
})

export class ServicoComponent implements OnInit {

    @Input() servico: any;
    @Input() Orcamento: any;
    @Input() usuario: any;
    // @Input() busca: Busca;
    // @Input() buscaRQ: BuscaServicoRQ;
    @Input() selecionar = true;
    @Input() buscando = true;
    
    estadosMensagem = {};
    backgroundImage = BACKGROUNDIMAGE;
    objServico: any;
    apiLocale = API;

    diasDaSemana = [
        { diaSemana: 'Dom' },
        { diaSemana: 'Seg' },
        { diaSemana: 'Ter' },
        { diaSemana: 'Qua' },
        { diaSemana: 'Qui' },
        { diaSemana: 'Sex' },
        { diaSemana: 'Sáb' }
    ]
    servicoCardLoadded: boolean;

    constructor(private modalService: NgbModal) { }

    ngOnInit() {
        this.servicoCardLoadded = false;
        console.log(this.usuario);
    }

    selecionou(servico: any) {
        //this.selecionouServico.emit(servico);
    }

    removeu(servico: any) {
        //this.removeuServico.emit(servico);
    }

    selecionarServico(servico) {

        const copiaProfundaServico = JSON.parse(JSON.stringify(servico));
        this.Orcamento.push(copiaProfundaServico);
        //this.selecionou.emit(servico);

        // Mostrar a mensagem flutuante para o serviço específico
        this.estadosMensagem[servico.Id] = true;
        setTimeout(() => {
            //this.estadosMensagem[servico.id] = false;
            delete this.estadosMensagem[servico.Id];
        }, 2000); // A mensagem desaparece após 2 segundos
    }
    // carregar(obj) {
    //   this.servicoCardLoadded = true;
    //   this.objServico = obj;

    //   if (this.isJson(this.objServico.ExtraIntegracao)) {
    //       this.objServico.ExtraIntegracao2 = JSON.parse(this.objServico.ExtraIntegracao);
    //   }
    // }
    onChangeData(data, servico) {
        const dataFormatada = data.split('T')[0];
        if (servico.Tarifas.length > 0) {
            servico.DataSelecionada = dataFormatada;
            console.log(servico);
            var tarifa = servico.Tarifas.filter(x => x.Data.split('T')[0] === dataFormatada);
            if (tarifa.length > 0) {
                servico.ExtraIntegracao = tarifa[0].Extra;
                servico.ValorNet = tarifa[0].ValorNet;
                servico.ValorVenda = tarifa[0].ValorVenda;
                servico.ValorListagem = tarifa[0].ValorListagem;
                servico.De = tarifa[0].Data;
                servico.Ate = tarifa[0].Data;
                console.log(servico);
            }


        }

    }

    formatDate(data: string): string {
        return data.split('T')[0];
    }

    abrirModal(content, servico) {

        this.objServico = servico;
        if (this.isJson(this.objServico.ExtraIntegracao)) {
            this.objServico.ExtraIntegracao2 = JSON.parse(this.objServico.ExtraIntegracao);
        }

        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
}
