import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BuscaHotelRQ } from '../../model';
import { API,TURISMO_HOTEL } from '../../app.config';
import { ITENSPORPAGINA } from '../../app.config';
import { CLIENT_ID } from '../../app.config';

// tslint:disable: max-line-length
// tslint:disable: ban-types

@Injectable()
export class HotelService {
  constructor( private http: HttpClient ) {}

    buscarHotel(busca: BuscaHotelRQ, pagina: Number): Observable<any> {
      return this.http.post<any>( `${TURISMO_HOTEL}/api/Hotel/buscaasync?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, busca, {headers: new HttpHeaders().set('noloader', 'true')});
    }

    removerHotel(hotel: any): Observable<any> {
      return this.http.delete<any>( `${TURISMO_HOTEL}/api/Hotel/${hotel.Id}`);
    }

    buscarPolitica(hotel: any): Observable<any> {
      return this.http.patch<any>( `${TURISMO_HOTEL}/api/hotel/politica/${hotel.ReservaId}/${hotel.Id}`, {}, {headers: new HttpHeaders().set('noloader', 'true')});
    }

    reservarHotel(politica: any): Observable<any> {
      return this.http.post<any>( `${TURISMO_HOTEL}/api/Hotel/reservar`, politica , {headers: new HttpHeaders().set('noloader', 'true')});
    }

    cancelarHotel(hotel: any): Observable<any> {
      return this.http.patch<any>( `${TURISMO_HOTEL}/api/hotel/cancelar/${hotel.ReservaId}/${hotel.Id}`, {}, {headers: new HttpHeaders().set('noloader', 'true')});
    }

    atualizarStatusHotel(hotel: any): Observable<any> {
      return this.http.patch<any>( `${TURISMO_HOTEL}/api/hotel/atualizar/${hotel.Id}`, {}, {headers: new HttpHeaders().set('noloader', 'true')});
    }

    detalhesHotel(hotel: any): Observable<any> {
          return this.http.patch<any>( `${TURISMO_HOTEL}/api/hotel/detalhes`, hotel, {headers: new HttpHeaders().set('noloader', 'true')});
        }

  }
